<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="production_page">
        <div class="production_description_area" v-if="userData && userData.city && parseInt(tile_id) === userData.city.tile_id">
            <div v-if="userData.city.user_id === userData.id">
                <div v-show="isTutorialLevelZero" class="backgroundTutorial">
                    <div class="tutorial">
                        Witamy w grze Piraci, diamenty i perły :)
                        <br>
                        Aby rozpocząć grę musisz zadbać o stały przyrost towarów.
                        <br>
                        Na twojej wyspie możesz produkować surowce i produkty.
                        <br>
                        Produkty te możesz wstawić do sprzedaży w miejskim sklepie, sprzedać lub wymienić u KUPCA.
                        <br>
                        Aktualnie ustawiliśmy dla ciebie najbardziej poszukiwane produkty- czyli
                        <br>
                        <div v-if="userData.producer === 1">
                            TYTOŃ, JEDWAB, LEN, PRZYPRAWY, KAKAO, SUKNO, ŻAGLE.
                        </div>
                        <div v-else-if="userData.producer === 2">
                            DREWNO, FUTRA, WINO, RUM, MIÓD, MIĘSO, SÓL.
                        </div>
                        <div v-else-if="userData.producer === 3">
                            RYBY, ZBOŻE, ŻELIWO, BRĄZ, WARZYWA, SŁÓD, CUKIER.
                        </div>
                        <br>
                        Aby dowiedzieć się, do czego będą potrzebne poszczególne towary- przejdź do zakładki ROZWÓJ.
                        <br>
                        Aby zmienić surowce produkowane na twojej wyspie- przejdź do zakładki ROZWÓJ i zrealizuj odpowiednie zadania
                        <br>
                        <br>
                        Po założeniu miasta dostępne pola produkcji to 10 akrów.
                        <br>
                        Na każdych 10 akrach możesz produkować jeden produkt dostępny z powyższej listy.
                        <br>
                        Ty decydujesz!
                        <br>
                        Pozostałe produkty musisz zdobyć na rynku poprzez zakup lub wymianę i / Slub zdobyć grabiąc inne okręty :) jak na pirata przystało !
                        <br>
                        <button @click="continueGame">Zrozumiałem, gram dalej</button>
                    </div>
                </div>
                <div v-show="!isTutorialLevelZero">
                    <div v-if="userData.city.citizens > 0">
                        <div>
                            Aby zdobyć kolejne pola pod produkcję- trzeba odpowiednio je przygotować.
                            <br>
                            Aby przygotować areał potrzebujesz 10 osób na każde nowe pole.
                            <br>
                            Aktualnie na twojej wyspie znajduje się {{ userData.city.citizens }} osób.<br>
                            Ilość pól do wykorzystania na wyspie: {{ userData.city.work_fields }}.
                            <br>
                            <div v-if="userData.city.workingCitizens">
                                <template v-for="singleWork in userData.city.workers" :key="singleWork.id">
                                    Przy zadaniu <strong v-if="userLang && userLang.products">{{ userLang.products['product_' + singleWork.product_id] }}</strong> pracuje {{ singleWork.citizens }} osób.<br>
                                    <TimerComponent v-if="singleWork.end_work_time" :endTime="singleWork.end_work_time" action="stopProduction" :oData="singleWork"></TimerComponent><br>
                                    produkcja: {{ singleWork.effect }} szt.
                                    <br>
                                </template>
                            </div>
                            <div v-else-if="userData.city.workingCitizens == 0">
                                Żaden z nich nie pracuje!
                                <br>
                            </div>
                            <div v-if="userData.city.citizens > userData.city.workingCitizens">
                                <WorkersForm
                                    :userLang="userLang"
                                    :userData="userData"
                                    :maxWorkers="maxWorkers"
                                    @productionUpdated="fetchData"/>
                            </div>
                            <div v-else>
                                <br><br>
                                Aby zlecić kolejne produkcje- musisz mieć więcej mieszkańców na wyspie.
                                <br>
                                Aby ich poziom wzrastał muszą czuć się bezpiecznie.
                                <br>
                                Zwiększ ilość armat na murach miasta, podpisz list Kaperski z królem Anglii, Hiszpanii, Portugalii lub Francji.
                                <br>
                                Aby podpisać taki list kaperski- wpływaj do obcych portów i odwiedzaj Gubernatorów tych Miast.
                                <br>
                                Jeśli się dogadacie- otrzymasz taki list!
                            </div>
                            <br><br>
                        </div>
                    </div>
                    <div v-else>
                        Aby zlecić kolejne produkcje- musisz mieć więcej mieszkańców na wyspie.
                        <br>
                        Aby ich poziom wzrastał muszą czuć się bezpiecznie.
                        <br>
                        Zwiększ ilość armat na murach miasta, podpisz list Kaperski z królem Anglii, Hiszpanii, Portugalii lub Francji.
                        <br>
                        Aby podpisać taki list kaperski- wpływaj do obcych portów i odwiedzaj Gubernatorów tych Miast.
                        <br>
                        Jeśli się dogadacie- otrzymasz taki list!
                    </div>
                </div>
            </div>
            <div v-else>
                Wizyta u gubernatora obcego miasta
            </div>
        </div>
        <div v-else>
            To nie twoje miasto!
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; Do miasta</div>
    </div>
</template>
<script>

import API from '@/utils/axios';
import {mapGetters, mapMutations} from "vuex";
import WorkersForm from "@/components/WorkersForm.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import TimerComponent from '@/components/TimerComponent.vue';

export default {
    data() {
        return {
            citizens: 0,
            product_id: null,
            tile_id: this.$route.params.tile_id,
            userData: {},
            isTutorialLevelZero: true,
            maxWorkers: 0,
            isLoading: true,
        };
    },
    components: {
        WorkersForm,
        LoadingComponent,
        TimerComponent
    },
    methods: {
        continueGame() {

            API.put(`/users`, {
                tutorial_level: 1
            })
            .then(response => {
                console.log('komunikat po update', response.data.message);
                this.$store.commit('setUserData', { tutorial_level: 1 });
                this.isTutorialLevelZero = false;
            })
            .catch(error => {
                console.error('Wystąpił błąd podczas aktualizacji poziomu tutoriala:', error);
            });
        },
        getProductRange(producer) {
            switch (producer) {
                case 1:
                    return [1, 2, 3, 4, 5, 6, 7, 22];
                case 2:
                    return [8, 9, 10, 11, 12, 13, 14, 22];
                case 3:
                    return [15, 16, 17, 18, 19, 20, 21, 22];
                default:
                    return [];
            }
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        },
        ...mapGetters(['getUserData', 'getLangTranslations']),
        ...mapMutations(['setCityData']),
        fetchData() {
            this.userData = this.getUserData();
            console.log('tutorialLevel =', this.userData.tutorial_level);
            if (this.userData.tutorial_level === 0) {
                this.isTutorialLevelZero = true;
            } else {
                this.isTutorialLevelZero = false;
            }
            this.fetchCityData(this.userData.city.id);
            this.userLang = this.getLangTranslations();
            this.maxWorkers = this.setMaxWorkers();
            console.log();
            this.isLoading = false;
            console.log('ładujemy dane i ustawiamy isLoadnig na false', this.isLoading);
        },
        setMaxWorkers() {
            if (this.userData && this.userData.city) {
                const workingCitizens = this.userData.city.workingCitizens;
                const citizens = this.userData.city.citizens;
                const sleepCitizens = citizens - workingCitizens;

                if (sleepCitizens > 0 && this.userData.city.work_fields > workingCitizens) {
                    return citizens - workingCitizens;
                }
            }
            return 0;
        },
        async fetchCityData(cityId) {
            console.log('cityId=', cityId);
            await API.get(`/city/${cityId}/details`)
                .then(response => {
                    console.log(response.data.data);
                    this.setCityData(response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching city data:', error);
                });
        },
    },
    mounted() {
        // Pobierz dane użytkownika po załadowaniu komponentu
        this.fetchData();
    },
    watch: {
        'userData.tutorial_level'(newValue) {
            this.isTutorialLevelZero = newValue === 0;
        }
    }
};
</script>

<style scoped>
.production_page {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/production_page.png') no-repeat center center fixed;
    background-size: cover;
}
.production_description_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    color: greenyellow;
}

.city {
    cursor: pointer;
    margin: 0 auto;
    width: 10%;
    height: 10%;
    position: absolute;
    bottom: 20px;
    left: 50%;
    background-color: white;
}
</style>