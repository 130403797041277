<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="governor_page" :style="backgroundImageStyle">
        <div class="governor_area">
            <div v-if="userData.city && userData.city.tile && parseInt(tile_id) === userData.city.tile.id">
                Witaj mój dzielny Kapitanie!<br>
                Przedstawiam ci raport danych naszej wyspy, miasteczka i fortu.<br>
            </div>
            <div v-if="freshCityData && userData.city">
                Ludność: {{ userData.city.citizens }} osób.<br>
                Pracujący: {{ userData.city.workingCitizens }} osób.<br>
                Poziom bezpieczeństwa: {{ freshCityData.safety_parameter }}<br>

                    Godzinowy przyrost ludności:<br><br>
                Żywność:
                    <div v-if="freshCityData.new_stats.food" :style="newCitizensStyle(freshCityData.new_stats.food)"
                         v-tooltip
                         :tooltip="freshCityData.new_stats.food < 0 ? 'Populacja spada! Miasto głoduje! Potrzebujemy warzyw, owoców, mięsa, ryb itp!' : 'Tak trzymać. Jedzenia mamy pod dostatkiem!'"
                        >
                        {{ freshCityData.new_stats.food }}
                    </div><br>
                Trunki:
                    <div v-if="freshCityData.new_stats.liguor" :style="newCitizensStyle(freshCityData.new_stats.liguor)"
                         v-tooltip
                         :tooltip="freshCityData.new_stats.liguor < 0 ? 'Populacja spada! Potrzebujemy rumu i wina!' : 'Tak trzymać. Wina i rumu mamy pod dostatkiem!'"
                        >
                        {{ freshCityData.new_stats.liguor }}
                    </div>
            </div>
            <div v-if="freshCityData.kaper_list">

            </div>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; Do miasta</div>
    </div>
</template>

<script>
import LoadingComponent from "@/components/LoadingComponent.vue";
import {mapGetters} from "vuex";
import API from "@/utils/axios";

export default {
    name: "GovernorPage",
    components: {
        LoadingComponent
    },
    data() {
        return {
            tile_id: this.$route.params.tile_id,
            isLoading: true,
            userData: {},
            freshCityData: {
                new_stats: {
                    food: 0,    // Domyślna wartość
                    liguor: 0   // Domyślna wartość
                }
            }
        };
    },
    methods: {
        ...mapGetters(['getUserData', 'getLangTranslations']),
        fetchData() {
            this.userData = this.getUserData();
            this.getFreshCityData();
            this.isLoading = false;
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika do miasta
            this.$router.push('/city/' + tile_id);
        },
        getFreshCityData() {
            // pobieramy dane statystyczne miasta
            API.get('/get_city_data/' + this.tile_id)
                .then(response => {
                    this.freshCityData = response.data.data;
                    this.freshCityData.new_stats = {
                        food: response.data.data.new_stats.food,
                        liguor: response.data.data.new_stats.liguor,
                    }
                });
        },
        newCitizensStyle(elementToStyle) {
            console.log('Stylizowanie elementu:', elementToStyle);
            return {
                color: elementToStyle < 0 ? 'red' : 'green'
            };
        }
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        backgroundImageStyle() {
            // Używamy require() do dynamicznego ładowania obrazów
            if (this.userData && this.userData.city && this.userData.city.icon) {
                // Używamy require() do dynamicznego ładowania obrazów
              // eslint-disable-next-line no-undef
                const imageUrl = require(`@/assets/images/governor_page_${this.userData.city.icon}.png`);
                return {
                    background: `url(${imageUrl}) no-repeat center center fixed`,
                    backgroundSize: 'cover'
                };
            }
        }
    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped>

.governor_page {
    width: 100%;
    height: 100%;
    position: relative;
}

.governor_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    color: greenyellow;
}

</style>
