<template>
    <div class="settings_page">
        <div class="settings_area">
            <h2>Twój avatar</h2>
            <div class="button_area">
                <h3>Wybierz Płeć</h3>
                <div class="button" @click="selectGender('male')">Mężczyzna</div>
                <div class="button" @click="selectGender('female')">Kobieta</div>
                <div class="button" @click="selectGender(null)">Wszystkie</div>
            </div>
            <div class="pirate-container">
                <div v-for="pirate in filteredPirates" :key="pirate" class="pirate-selection">
                    <div :class="['pirate_icon', pirate, { selected: pirate === selectedPirate }]" @click="selectPirate(pirate)"></div>
                </div>
            </div>

            <h2>Wersja językowa</h2>
            <div class="language-container">
                <h3>Wybierz język gry</h3>
                <div v-for="language in languages" :key="language" class="language-selection">
                    <div :class="['language_icon', language.class, { selected: language.code === selectedLanguage }]"
                         v-tooltip :tooltip="language.name"
                         @click="selectLanguage(language.code)">
                    </div>
                </div>
            </div>

            <button @click="saveSettings">Zapisz</button>
            <div v-if="message" :class="['alert', message.class]">{{ message.text }}</div>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; Do miasta</div>
    </div>
</template>

<script>
import API from "@/utils/axios";
import {mapGetters, mapMutations} from "vuex";

export default {
    name: "SettingsPage",
    data() {
        return {
            pirates: [
                'm_face_1',
                'w_face_1',
                'm_face_2',
                'w_face_2',
                'm_face_3',
                'w_face_3',
                'm_face_4',
                'w_face_4',
                'm_face_5',
                'w_face_5',
                'w_face_6',
                'm_face_6',
                'm_face_7',
                'w_face_7',
                'w_face_8',
                'm_face_8',
                'm_face_9',
                'w_face_9',
                'm_face_10',
                'w_face_10'
            ],
            selectedGender: null,
            selectedPirate: null,
            selectedLanguage: null,
            languages: [
                { name: 'Polski', code: 'pl', class: 'pol_lang' },
                { name: 'Niemiecki', code: 'de', class: 'ger_lang' },
                { name: 'Angielski', code: 'en', class: 'eng_lang' },
            ],
            message: {},
            tile_id: null,
        };
    },
    watch: {
        getUserData: {
            immediate: true,
            handler(userData) {
                if (userData) {
                    this.selectedPirate = userData.avatar_class;
                    this.selectedLanguage = userData.lang;
                    console.log('userData', userData);
                    this.tile_id = userData.tile_id;
                    if (this.selectedPirate && this.selectedPirate.startsWith('m_face')) {
                        this.selectedGender = 'male';
                    } else if (this.selectedPirate && this.selectedPirate.startsWith('w_face')) {
                        this.selectedGender = 'female';
                    } else {
                        this.selectedGender = null;
                    }
                }
            }
        }
    },
    computed: {
        ...mapGetters(['getUserData']),
        filteredPirates() {
            if (!this.selectedGender) {
                return this.pirates;
            } else if (this.selectedGender === 'male') {
                return this.pirates && this.pirates.filter(pirate => pirate.startsWith('m_face'));
            } else if (this.selectedGender === 'female') {
                return this.pirates && this.pirates.filter(pirate => pirate.startsWith('w_face'));
            }
            return [];
        }
    },
    methods: {
        ...mapMutations(['setUserData']),
        selectGender(gender) {
            this.selectedGender = gender;
        },
        selectPirate(pirate) {
            this.selectedPirate = pirate;
        },
        selectLanguage(code) {
            this.selectedLanguage = code;
        },
        saveSettings() {
            if (!this.selectedPirate || !this.selectedLanguage) {
                this.message = {
                    text: 'Proszę wybrać avatar i język.',
                    class: 'error'
                };
                return;
            }

            this.message = null;
            const settings = {
                icon: this.selectedPirate,
                language: this.selectedLanguage,
            };
            API.post('/settings', settings)
                .then(response => {
                    this.setUserData({
                        avatar_class: this.selectedPirate,
                        lang: this.selectedLanguage
                    });
                    this.message = {
                        text: response.data.text,
                        class: 'info'
                    };
                    console.log('mesage', this.message);
                })
                .catch(error => {
                    this.message = {
                        text: 'Wystąpił błąd podczas zapisywania ustawień.',
                        class: 'info'
                    };
                    console.error(error);
                });
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        }
    },
};
</script>

<style scoped>

.settings_page {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('@/assets/images/settings_page.png') no-repeat center center fixed;
    background-size: cover;
}

.button_area {
    display: flex;
    color: greenyellow;
    width: 80%;
    margin: 0 auto;
    gap: 20px;
}

.pirate-container, .language-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin: 0 auto;
    max-height: 60vh;
    overflow-y: auto;
    border: 1px solid #ccc;
    padding: 10px;
}

.settings_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 60%;
    margin: 0 auto;

}

.pirate-selection, .language-selection {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pirate_icon {
    width: 150px;
    height: 150px;
    cursor: pointer;
    border: 2px solid transparent;
}

.language_icon {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 2px solid transparent;
}

.pirate_icon:hover, .language_icon:hover {
    border-color: gold;
}

.pirate_icon.selected, .language_icon.selected {
    border-color: #b87333;
}

.eng_lang {
    background: url('@/assets/images/eng_lang.png') no-repeat center center;
    background-size: cover;
}

.pol_lang {
    background: url('@/assets/images/pol_lang.png') no-repeat center center;
    background-size: cover;
}

.ger_lang {
    background: url('@/assets/images/ger_lang.png') no-repeat center center;
    background-size: cover;
}

</style>
