// store.js

import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import API from "@/utils/axios";

export default createStore({
    state: {
        userData: null,
        gunTypes: [],
        fleetTypes: [],
        sUserLang: null,
        langTranslations: {},
        mapPieces: {},
        totalMapPieces: 16,
        isDialogueOpen: true,
        questions: null,
        technologyTree: {},

    },
    mutations: {
        closeDialogue(state) {
            state.isDialogueOpen = false;
        },
        openDialogue(state) {
            state.isDialogueOpen = true;
        },
        setUserData(state, sendedUserData) {
            state.userData = { ...state.userData, ...sendedUserData };
        },
        setShipData(state, sendedShipData) {
            state.userData.ship = { ...state.userData.ship, ...sendedShipData };
        },
        setCityData(state, sendedCityData) {
            state.userData.city = { ...state.userData.city, ...sendedCityData };
        },
        setTreasureData(state, sendedTreasureData) {
            state.userData.treasure = { ...state.userData.treasure, ...sendedTreasureData };
        },
        setSUserLang(state, lang) {
            state.sUserLang = lang;
        },
        setLangTranslations(state, lang) {
            state.langTranslations = lang;
            state.questions = lang.questions;
        },
        setMapPieces(state, pieces) {
            // Inicjalizacja domyślnych wartości dla 16 fragmentów
            state.mapPieces = {};
            for (let i = 1; i <= state.totalMapPieces; i++) {
                state.mapPieces[i] = {
                    piece_number: i,
                    count: 0
                };
            }

            // Update state with data from API
            pieces.forEach(piece => {
                if (piece && typeof piece.piece_number === 'number' && piece.piece_number > 0) {
                    state.mapPieces[piece.piece_number] = {
                        piece_number: piece.piece_number,
                        count: piece.count
                    };
                }
            });
        },
        addMapPiece(state, piece) {
            if (Object.prototype.hasOwnProperty.call(state.mapPieces, piece.piece_number)) {
                state.mapPieces[piece.piece_number].count += 1;
            }
        },
        setGunTypes(state, gunTypes) {
            state.gunTypes = gunTypes;
        },
        setFleetTypes(state, fleetTypes) {
            state.fleetTypes = fleetTypes;
        },
        setTechnologyTree(state, tree) {
            state.technologyTree = tree;
        },
        updateCityProducts(state, products) {
            if (state.userData && state.userData.city) {
                state.userData.city.products = products;
            }
        },
    },
    getters: {
        getUserData: state => state.userData,
        getSUserLang: state => state.sUserLang,
        getLangTranslations: state => state.langTranslations,
        getGunTypes: state => state.gunTypes,
        getFleetTypes: state => state.fleetTypes,
        getTechnologyTree: state => state.technologyTree,
    },
    actions: {
        async fetchMapPieces({ commit }) {
            await API.get(`/mapPieces`)
                .then(response => {
                    const piecesMap = {};
                    // Inicjalizacja wszystkich kluczy od 1 do 16 z wartością 0
                    for (let i = 1; i <= 16; i++) {
                        piecesMap[i] = 0;
                    }

                    // Przypisanie odpowiednich wartości dla otrzymanych elementów
                    if (Array.isArray(response.data.pieces)) {
                        commit('setMapPieces', response.data.pieces);
                    } else {
                        console.error('Oczekiwano tablicy, otrzymano:', response.data.pieces);
                    }
                })
                .catch(error => {
                    console.error('Error in getMapElements:', error);
                });
        },
        collectMapPiece({ commit }) {
            return API.post(`/collectMap`)
            .then(response => {
                commit('addMapPiece', response.data.newPiece);
                return response;
            })
            .catch(error => {
                console.error('Error sending answer:', error);
                throw error;
            });
        },
        async fetchTranslations({ commit, state }, lang = state.sUserLang) {
            if (!lang) {
                lang = navigator.language.slice(0, 2) || 'pl';
            }

            if (lang === state.sUserLang) {
                return;
            }
            console.log(`Pobieranie tłumaczeń dla języka: ${lang}`);
            await API.get(`/translations/${lang}`)
                .then(response => {
                    console.log('Tłumaczenia:', response.data);
                    commit('setLangTranslations', response.data);
                    commit('setSUserLang', lang);
                })
                .catch(error => {
                    console.error('Błąd podczas pobierania tłumaczeń:', error);
                });
        },
        async fetchGameData({ commit, state}) {
            if (state.gunTypes.length > 0 && state.fleetTypes.length > 0) {
                return;
            }
            await API.get(`/getGameData/`)
                .then(response => {
                    const { gunTypes, fleetTypes } = response.data;

                    // Zapisz dane do stanu Vuex za pomocą mutacji
                    commit('setGunTypes', gunTypes);
                    commit('setFleetTypes', fleetTypes);

                    console.log('Pobrano dane armat:', gunTypes);
                    console.log('Pobrano dane statków:', fleetTypes);
                })
                .catch(error => {
                    console.error('Błąd podczas pobierania danych gry:', error);
                });
        },
        async fetchTechnologyTree({ commit }) {
            console.log('pobieramy technologie usera');
            await API.get("/technologies")
            .then(response => {
                const technologyTree = response.data.technologies;
                console.log('technologyTree', technologyTree);
                commit("setTechnologyTree", technologyTree);
            })
            .catch(error => {
                console.error("Błąd podczas pobierania drzewa technologii:", error);
            });
        },
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage
        })
    ]
});
