<template>
    <div v-if="userData" class="user_info">
        <div
            v-tooltip tooltip="Ustawienia"
            :class="['gap_item menu_name', userData.avatar_class]"
            @click="goToSettings()">
                <span>
                    {{ userData.username }}
                </span>
        </div>
        <div v-if="userData.averagePosition"
            v-tooltip  :tooltip="'Suma punktów: ' + userData.averagePosition.totalScore"
            class="gap_item menu_points"
            @click="goToStats()">
            <span>
                {{ userData.averagePosition ? userData.averagePosition.position : '-' }}
            </span>
        </div>
        <div v-if="userData.treasure"
             v-tooltip tooltip="Złoto"
             class="gap_item menu_gold">
                <span>
                    {{ userData.treasure.golds }}
                </span>
        </div>
        <div v-if="userData.treasure"
             v-tooltip tooltip="Dublony"
             class="gap_item menu_dublons">
                <span>
                    {{ userData.treasure.dublons }}
                </span>
        </div>
        <div v-if="userData.treasure"
             v-tooltip tooltip="Perły"
             class="gap_item menu_pearls">
                <span>
                    {{ userData.treasure.pearls }}
                </span>
        </div>
        <div v-if="userData.treasure"
             v-tooltip tooltip="Diamenty"
             class="gap_item menu_diamonds">
                <span>
                    {{ userData.treasure.diamonds }}
                </span>
        </div>
        <router-link :to="{ path: '/logout' }">
            <div v-tooltip tooltip="Wyloguj" class="gap_item logout"></div>
        </router-link>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    name: 'UserInfo',
    data() {
        return {
            userData: {},  // Zainicjowane jako pusty obiekt
            isDataLoaded: false
        };
    },
    methods: {
        ...mapGetters(['getUserData']),
        fetchData() {
            const userData = this.getUserData();
            if (userData) {
                this.userData = userData;
                this.isDataLoaded = true;
            }
        },
        goToStats() {
            // Przekieruj użytkownika do statystyk
            this.$router.push('/stats');
        },
        goToSettings() {
            // Przekieruj użytkownika do ustawień
            this.$router.push('/settings');
        },
    },
    mounted() {
        this.fetchData();
    },
    watch: {
        getUserData(newValue) {
            if (newValue) {  // Reakcja na załadowanie danych użytkownika
                this.fetchData();
            }
        }
    }
};
</script>

<style scoped>

.user_info {
    display: flex;
    position: fixed;
    border-radius: 8px;
    height: 80px;
    flex-direction: row;
    width: 50%;
    gap: 2%;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);
    color: greenyellow;
    font-family: 'Pirata One', cursive;
    justify-content: space-around;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)), url('@/assets/images/menu_bg.png') repeat-x center center;
    background-size: cover, cover;
    background-blend-mode: overlay;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.user_info span {
    padding: 2px;
}

.gap_item {
    width:75px;
    height:65px;
    padding-top: 45px;
    font-size: 24px;
}

.menu_points {
    width:100px;
    background: url('@/assets/images/stats_icon.png') no-repeat center center;
    background-size: cover;
    background-position: 0 0;
    cursor: pointer;
}

.menu_name {
    width: 60px;
    cursor: pointer;
}

.menu_gold {
    background: url('@/assets/images/gold_icon.png') no-repeat center center;
    background-size: cover;
}

.menu_diamonds {
    background: url('@/assets/images/diamonds_icon.png') no-repeat center center;
    background-size: cover;
}

.menu_pearls {
    background: url('@/assets/images/pearls_icon.png') no-repeat center center;
    background-size: cover;
}

.menu_dublons {
    background: url('@/assets/images/dublons_icon.png') no-repeat center center;
    background-size: cover;
}

.logout {
    background: url('@/assets/images/logout.png') no-repeat center center;
    background-size: cover;
    margin-top: 6px;
}

</style>
