<template>
    <div class="pirate_dialogue">
        <h2>Rozmowa z piratem</h2>
        <p v-html="message.text"></p>
        <div v-if="responseType === 'continue'">
            <p>Proszę spróbuj ponownie lub zadaj inne pytanie.</p>
        </div>
        <div v-if="responseType === 'finished'">
            <p>Dziękujemy za rozmowę!</p>
        </div>
        <div v-if="responseType === 'finished' && message.isCorrect">
            <p v-html="responseText"></p><br>
            <TreasureMap/>
            <button v-if="buttonVisible" @click="handleCollectMapPiece">Pobierz fragment mapy</button>
        </div>
        <div v-if="message.question">
            <p>Pytanie: {{ message.question }}</p>
            <button v-for="answer in message.answers" :key="answer" @click="sendAnswer(answer.id, message.question_id)">
                {{ answer.content }}
            </button>
        </div>
        <button v-for="button in message.buttons" :key="button" @click="handleButton(button)">
            {{ formatButtonLabel(button) }}
        </button>
    </div>
</template>

<script>

import { mapActions } from 'vuex';
import TreasureMap from "@/components/TreasureMap.vue";
import API from "@/utils/axios";

export default {
    props: ['pirateId'],
    data() {
        return {
            message: {},
            responseType: '',  // Dodaj to, jeśli jeszcze nie zdefiniowano
            responseText: 'Świetnie, tak jak obiecałem, mam dla ciebie fragment mapy skarbów.',
            buttonVisible: true
        };
    },
    components: {
        TreasureMap,
    },
    watch: {
        pirateId(newPirateId, oldPirateId) {
            if (newPirateId !== oldPirateId) {
                this.interactWithPirate(newPirateId);
            }
        }
    },
    methods: {
        ...mapActions(['collectMapPiece']),
        handleCollectMapPiece() {
            this.buttonVisible = false;
            this.responseText = 'No to losujemy :)';
            this.collectMapPiece()
            .then(() => {
                this.responseText = 'Wylosowany fragment mapy dodano do twojej kolekcji';
            }).catch(error => {
                this.buttonVisible = true;
                console.error('Failed to collect map piece:', error);
                // Obsługa błędu, np. wyświetlenie komunikatu dla użytkownika
            });
        },
        async interactWithPirate(pirateId) {
            await API.get(`/pirate/${pirateId}/interact`)
            .then(response => {
                this.message = response.data.message;
            }).catch(error => {
                console.error('Error interacting with pirate:', error);
                this.message = 'Wystąpił błąd podczas interakcji z piratem.';
            });
        },
        async sendAnswer(answerId, questionId) {
            await API.post(`/question/${questionId}/answer`, {
                answer_id: answerId,
            })
            .then(response => {
                this.message.text = response.data.message;
                this.responseType = response.data.isCorrect ? 'finished' : 'continue';
                this.message.question = '';
                this.message.isCorrect = response.data.isCorrect;
            }).catch(error => {
                console.error('Error sending answer:', error);
                this.message = 'Wystąpił błąd podczas wysyłania odpowiedzi.';
            });
        },
        handleButton(button) {
            if (button === 'end') {
                this.endDialogue();
            } else if (button === 'hire') {
                this.hirePirate(this.pirateId);
            }
        },
        formatButtonLabel(button) {
            if (button === 'end' || button === 'not_interested') {
                return 'Zakończ rozmowę';
            } else if (button === 'hire') {
                return 'Zatrudnij pirata';
            }
        },
        hirePirate(pirateId) {
            API.post(`/pirate/${pirateId}/assign`)
                .then(response => {
                    this.message.text = response.data.message;
                    this.responseType = 'finished';
                    this.message.buttons = {};
                    setTimeout(() => {
                        this.$emit('end-dialogue'); // Emitowanie zdarzenia do rodzica
                    }, 2000);
                })
                .catch(error => {
                    console.error('Błąd przy zatrudnianiu pirata:', error);
                    this.message = 'Nie udało się zatrudnić pirata.';
                });
        },
        endDialogue() {
            // Ustawienie komunikatu o zakończeniu i zmiana typu odpowiedzi
            this.message = {};
            this.responseType = 'finished';

            // Opóźnienie ukrycia komponentu o 2 sekundy
            setTimeout(() => {
                this.$emit('end-dialogue'); // Emitowanie zdarzenia do rodzica
            }, 2000);
        }
    },
    mounted() {
        this.interactWithPirate(this.pirateId);
    }
};
</script>

<style scoped>
.pirate_dialogue {
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative;
    z-index: 1;
    color: greenyellow;
    background: rgba(0, 0, 0, .7);
}

button {
    margin: 5px;
}
</style>
