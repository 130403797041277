<template>
    <div v-if="isVisible" ref="modal" class="modal-content" :style="{ top: modalPosition.top, left: modalPosition.left }">
        <div v-if="isAlert" :class="alertType">
            <div v-html="modalData.message"></div>
        </div>
        <div v-else>
            <div v-if="modalData.data.cityData" id="city_area">
                <div class="city_description_area">
                    <div v-html="modalData.data.cityData"></div>
                    <button v-if="modalData.inCity" @click="goToCityPage(modalData.goToCity)">Do miasta</button>
                    <button v-else @click="attackCityAction(modalData.goToCity)">Atakuj miasto</button>
                </div>
            </div>
            <div v-if="modalData.data.shipData" id="ship_area">
                <div class="ship_description_area">
                    <div v-html="modalData.data.shipData"></div>
                    <button v-if="modalData.onBoard" @click="goToShipPage(modalData.goToShip)">Na pokład</button>
                    <button v-if="modalData.attackShip" @click="attackShipAction(modalData.goToShip)">Atakuj statek</button>
                    <button v-if="modalData.startCruise" @click="startCruiseAction(modalData.startCruise)">{{ modalData.startCruiseButtonText ? modalData.startCruiseButtonText : 'Płyń w to miejsce' }}</button>
                </div>
            </div>
        </div>
        <button @click="closeModal" class="close_modal" v-tooltip tooltip="zamknij">X</button>
    </div>
</template>

<script>
import API from "@/utils/axios";

export default {
    props: ['modalData', 'modalPosition', 'isAlert', 'alertType'],
    data() {
        return {
            isVisible: false
        };
    },
    methods: {
        openModal() {
            this.isVisible = true;
        },
        closeModal() {
            this.isVisible = false;
        },
        goToShipPage(tile_id) {
            // Przekieruj użytkownika do strony sklepu
            this.$router.push('/ship/'+tile_id);
        },
        goToCityPage(tile_id) {
            // Przekieruj użytkownika do strony sklepu
            this.$router.push('/city/'+tile_id);
        },
        attackCityAction(tile_id) {
            // Przekieruj użytkownika do strony ataku na miasto
            this.$router.push('/attackCity/'+tile_id);
        },
        attackShipAction(tile_id) {
            // Przekieruj użytkownika do strony ataku na statek
            this.$router.push('/attackShip/'+tile_id);
        },
        startCruiseAction(tile_id) {
            if (!this.isUserLoggedIn()) {
                // Przekieruj użytkownika do formularza logowania
                this.$router.push('/login');
                return;  // Wczesne zakończenie, jeśli użytkownik nie jest zalogowany
            }

           API.get(`/setCruise/${tile_id}`)
            .then(() => {
                this.closeModal();
            })
            .catch(error => {
                console.error('Error:', error);
            });
        },
        isUserLoggedIn() {
            // Sprawdź, czy token użytkownika jest dostępny w sessionStorage
            const token = sessionStorage.getItem('token');
            // Zwróć true, jeśli token istnieje i jest niepusty
            return token && token !== '';
        }
    }
};
</script>

<style scoped>

.modal-content {
    position:absolute;
    width: auto;
    height: auto;
    z-index: 4;
    background: rgba(0, 0, 0, .7);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    border-radius: 9px;
    color: greenyellow;
}

#city_area {
    width: 370px;
    height: 140px;
    background: url('@/assets/images/island_big.png') no-repeat;
    background-size: contain;
    margin-bottom: 10px;
}

.city_description_area {
    width: 70%;
    left: 30%;
    position: relative;
}

#ship_area {
    width: 370px;
    height: 140px;
    background: url('@/assets/images/island_big.png') no-repeat;
    background-size: contain;
    margin-bottom: 10px;
}

.ship_description_area {
    width: 70%;
    left: 30%;
    position: relative;
}

.alert-modal {
    padding: 20px;
    text-align: center;
}

.close_modal {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 5px;
    top: 5px;
    font-size: 11px;
    text-align: center;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
}

</style>