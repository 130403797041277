// src/utils/axios.js

import axios from 'axios';
import { getToken } from './utils';

const API = axios.create({
  // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_API_URL
});

API.interceptors.request.use(function (config) {
    // Sprawdzenie, czy żądanie nie jest żądaniem logowania
    if (!config.url.endsWith('/login') && !config.url.endsWith('/confirm-email') && !config.url.endsWith('/register')) {
        const token = getToken();
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
    }
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default API;
