<template>
    <div>
        <h1>Wylogowywanie...</h1>
    </div>
</template>

<script>
import API from "@/utils/axios";

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Logout',
    mounted() {
        // Wyloguj użytkownika po zamontowaniu komponentu
        this.logoutUser();
    },
    methods: {
        async logoutUser() {
            await API.post(`/logout`)
            .then(response => {
                if (response.data && response.data.success) {
                    sessionStorage.clear(); // Czyść sessionStorage
                    this.$router.push('/'); // Przekierowanie do strony głównej
                } else {
                    // Jeśli odpowiedź zawiera pole success i jest fałszywe lub brakuje pola success
                    throw new Error('Wylogowanie nieudane: ' + (response.data.message || 'Brak dodatkowej wiadomości'));
                }
            })
            .catch(error => {
                console.error('Błąd podczas wylogowywania:', error);
            });
        }
    }
};
</script>

<style scoped>

</style>
