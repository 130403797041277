<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="shipyard_page">
        <h1>Stocznia</h1>
        <div v-if="fleet && fleet.length > 0" id="fleetList">
            <div class="ship-list">
                <div v-for="ship in fleet" :key="ship.id" class="ship-item">
                    <div class="ship-info">
                        <p><strong>Nazwa:</strong>
                            <span v-tooltip :tooltip="userLang.ships['ship_' + ship.id]" class="ship_description">{{ ship.name }}</span>
                        </p>
                        <p><strong>Cena:</strong> {{ ship.cost }}</p>
                        <p><strong>Ładowność:</strong> {{ ship.capacity }}</p>
                        <p><strong>Załoga:</strong> {{ ship.pirates }}</p>
                        <p><strong>Zasięg jednego rejsu:</strong> {{ ship.cruise_range }} pól</p>
                        <p><strong>Prędkość:</strong> {{ ship.speed }} węzłów</p>
                        <p><strong>Ilość masztów:</strong> {{ ship.mast }}</p>
                        <p><strong>Wytrzymałość:</strong> {{ ship.efficiency }} pkt.</p>
                        <p><strong>Max. ilość dział:</strong> {{ ship.guns }}</p>
                    </div>
                    <button @click="buyShip(ship)" :disabled="ship.cost > userData.treasure.dublons" :class="{ 'disabled_element': ship.cost > userData.treasure.dublons }">Kup</button>
                </div>
            </div>
        </div>
        <div v-else-if="fleet.length === 0">
            <p>Brak dostępnych statków do kupienia.</p>
        </div>
        <div class="toCity" @click="goToCity(tile_id)">&larr; Do miasta</div>
        <div class="toShop" @click="goToShop(tile_id)">&larr; Do sklepu</div>
    </div>
    <ModalComponent
        ref="modal"
        :modalData="modalData"
        :modalPosition="modalPosition"
        :isAlert="isAlert"
        :alertType="alertType"
    />
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ModalComponent from '@/components/ModalComponent.vue';
import API from "@/utils/axios";
import LoadingComponent from '@/components/LoadingComponent.vue';

export default {
    name: 'ShipyardPage',
    components: {
        ModalComponent,
        LoadingComponent
    },
    data() {
        return {
            fleet: false,
            tile_id: this.$route.params.tile_id,
            userData: {},
            modalData: {},
            modalPosition: { top: 0, left: 0 },
            messages: [],
            isAlert: false,
            alertType: 'info',
            isLoading: true,
            userLang: {},
        };
    },
    mounted() {
        this.fetchData();
        this.isLoading = false;
    },
    methods: {
        ...mapGetters(['getUserData', 'getLangTranslations', 'getFleetTypes']),
        ...mapMutations(['setUserData', 'setShipData', 'setTreasureData']),
        fetchData() {
            this.userData = this.getUserData();
            this.userLang = this.getLangTranslations();
            this.fleet = this.getFleetTypes();
        },
        buyShip(ship) {
            const data = {
                fleet_id: ship.id,
                tile_id: this.tile_id
            };

            API.post(`/ship`, data)
            .then(response => {
                if (response.data.shipIsCreated) {
                    const newDublons = this.userData.treasure.dublons - ship.cost;
                    this.setTreasureData({ dublons: newDublons });
                    this.setUserData({ ship: response.data.ship });
                    this.setShipData(response.data.ship);
                    this.modalData.message = 'statek kupiono';
                    this.isAlert = true;
                    this.modalPosition = {
                        top: '20px',
                        left: '50px'
                    };
                    this.$refs.modal.openModal();
                }
            })
            .catch(error => {
                console.error('Błąd podczas wysyłania danych statku:', error);
            });

        },
        goToShop(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/shop/'+ tile_id);
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/'+ tile_id);
        },
    }
};
</script>

<style scoped>

.shipyard_page {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('@/assets/images/shipyard_page.png') no-repeat center center fixed;
    background-size: cover;
}

#fleetList {
    max-height: 550px;
    width: 90%;
    overflow-y: auto;
    margin: 0 auto;
}
.ship-list {
    display: flex;
    flex-wrap: wrap;
}

.ship-item {
    width: 18%;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    color: greenyellow;
    background: rgba(0, 0, 0, .7);
}

.ship-info {
    margin-bottom: 10px;
}

.ship_description {
    cursor: help;
}

</style>
