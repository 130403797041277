<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="ship-page">
        <div class="ship_area">
            <h3>Armaty na wyposażeniu statku</h3>
            <div v-if="Object.keys(groupedGuns).length > 0">
                <div v-for="(gunType, index) in groupedGuns" :key="index">
                    <h3>{{ gunType.typeName }}</h3>
                    <div>
                        {{ gunType.count }} x {{ gunType.typeName }}: suma mocy {{ gunType.totalDamage }}
                    </div>
                </div>
                <p>Moc wszystkich armat: {{ totalPowerGun }}</p>
            </div>
            <div v-else>
                <p>Brak armat na wyposażeniu.</p>
            </div>
            <div v-if="gunInShop.length > 0">
                Armaty w sklepie
                <li v-for="product in gunInShop" :key="product.id">
                    <input type="checkbox" v-model="selectedGuns[product.product_id]" :true-value="true" :false-value="false">
                    {{ product.productDetails.name }} - {{ product.quantity }} szt. -
                    <input type="number" v-model.number="selectedQuantities[product.product_id]" :max="product.quantity" min="0">
                </li>
                <button @click="executeTransfer">Uzbrój statek</button>
            </div>
            <div class="pirate_skills_training">
                <h1>Umiejętności załogi</h1>
                <div class="skills">
                    <div v-for="(skill, skill_id) in skills" :key="skill_id" class="skill">
                        <div :class="['training', 'training_' + skill_id]">
                            <div class="text_area" v-tooltip :tooltip="userLang.app['training_desc_' + skill_id] + '<br> Czas szkolenia: ' + skills[skill_id].time">
                                {{ userLang.app['training_' + skill_id] }}: {{ skill.value }}
                                <!-- Sprawdzenie czy umiejętność jest w trakcie treningu -->
                                <div v-if="currentTrainingSkill && parseInt(currentTrainingSkill) === parseInt(skill_id)">
                                    <p>Trening w toku.<br></p>
                                    <TimerComponent v-if="remainingTime" :endTime="remainingTime" action="stopTraining" :oData="skill"></TimerComponent>
                                </div>
                            </div>
                            <button
                                v-if="!currentTrainingSkill"
                                class="train_skill"
                                v-tooltip
                                :tooltip="(!canAfford(skill) ? 'Nie masz tyle dublonów.<br>Zdobądź więcej dublonów' : '')"
                                :disabled="!canAfford(skill)"
                                :class="{ 'disabled_element': !canAfford(skill) }"
                                @click="trainSkill(skill_id)"
                            >Trenuj za {{ skill.cost }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="hasShipInPort" class="toCity" @click="goToCity(tile_id)">&larr; Do miasta</div>
        <div class="toMap" v-tooltip tooltip="Mapa" @click="goToMap">&larr; Do mapy</div>
    </div>
</template>

<script>

import LoadingComponent from '@/components/LoadingComponent.vue';
import TimerComponent from '@/components/TimerComponent.vue';
import {mapGetters, mapMutations} from "vuex";
import API from "@/utils/axios";

export default {
    name: "ShipPage",
    components: {
        LoadingComponent,
        TimerComponent
    },
    data() {
        return {
            tile_id: this.$route.params.tile_id,
            userData: null,
            userLang: null,
            shipData: null,
            groupedGuns: {},
            totalPowerGun: 0,
            isLoading: true,
            gunInShop: [],
            selectedGuns: {},
            selectedQuantities: {},
            hasShipInPort: false,
            skills: {},
            currentTrainingSkill: null,
            remainingTime: null,
        };
    },
    methods: {
        ...mapMutations(['setUserData']),
        ...mapGetters(['getUserData', 'getLangTranslations']),
        fetchData() {
            this.userData = this.getUserData();
            const shipData = this.userData.ship;
            console.log('shipData', shipData);
            const cityData = this.userData.city;
            console.log('cityData', cityData);
            this.userLang = this.getLangTranslations();
            if (cityData && cityData.products && shipData.tile.id === cityData.tile.id) {
                this.gunInShop = cityData.products.filter(product => product.product_type === 'gun' && product.quantity > 0) ?? [];
            }
            this.groupedGuns = shipData.groupedGuns;
            this.totalPowerGun = shipData.totalPowerGun;
            // pobieramy skille pirata
            this.fetchSkills();
            this.isLoading = false;
        },
        goToCity(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/city/' + tile_id);
        },
        addGunToShip(gunData) {
            // dodajemy armaty na wyposażenie statku
            API.post('/set-new-localization-for-gun', gunData)
                .then(response => {
                    const userData = response.data.userData;
                    this.setUserData(userData);
                    this.gunInShop = userData.city.products.filter(product => product.product_type === 'gun' && product.quantity > 0) ?? [];
                    this.groupedGuns = userData.ship.groupedGuns;
                    this.totalPowerGun = userData.ship.totalPowerGun;
                });
        },
        executeTransfer() {
            const gunsToTransfer = Object.keys(this.selectedGuns)
                .filter(id => this.selectedGuns[id] && this.selectedQuantities[id] > 0)
                .map(id => ({ id, quantity: this.selectedQuantities[id] }));

            if (gunsToTransfer.length > 0) {
                this.addGunToShip({ guns: gunsToTransfer, localization: 1 });
            }
        },
        async checkShipInPort() {
            await API.get(`/ship`, {
                params: {
                    tile_id: this.tile_id
                }
            })
                .then(response => {
                    this.hasShipInPort = response.data.hasShipInPort;
                    this.fleet_id = response.data.fleet_id;
                })
                .catch(error => {
                    console.error('Błąd podczas sprawdzania statku w porcie:', error);
                });
        },
        goToMap() {
            // Przekieruj użytkownika na mapę
            this.$router.push({ path: '/map', query: { center: 'ship' } });
        },
        fetchSkills() {
            API.get('/skills')  // Adres URL do zmiany zgodnie z twoją konfiguracją backendu
                .then(response => {
                    this.skills = response.data.skills;
                    this.currentTrainingSkill = response.data.currentTrainingSkill;
                    this.remainingTime = response.data.remainingTime;
                })
                .catch(error => {
                    console.error("There was an error fetching the skills:", error);
                });
        },
        trainSkill(skill_id) {
            API.post(`/train`, {
                skill_id: skill_id
            })
                .then(response => {
                    this.currentTrainingSkill = response.data.currentTrainingSkill;
                    this.remainingTime = response.data.remainingTime;
                })
                .catch(error => {
                    console.error('Error training :', error);
                });
        },
        canAfford(skill) {
            console.log('bublons', this.userData.treasure.dublons);
            return skill.cost < this.userData.treasure.dublons;
        },
    },
    mounted() {
        this.fetchData();
        this.checkShipInPort();
    },
}
</script>

<style scoped>
.ship-page {
    position: relative;
    height: 100vh;
    width: 100%;
    background: url('@/assets/images/ship_page.png') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    justify-content: space-between;
}

.ship_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 80%;
    margin: 0 auto;
    color: greenyellow;
}

.training_1 {
    background: url('@/assets/images/training/training_1.png') no-repeat center center;
    background-size: cover;
}

.training_2 {
    background: url('@/assets/images/training/training_2.png') no-repeat center center;
    background-size: cover;
}

.training_3 {
    background: url('@/assets/images/training/training_3.png') no-repeat center center;
    background-size: cover;
}

.training_4 {
    background: url('@/assets/images/training/training_4.png') no-repeat center center;
    background-size: cover;
}

.training_5 {
    background: url('@/assets/images/training/training_5.png') no-repeat center center;
    background-size: cover;
}

.training_6 {
    background: url('@/assets/images/training/training_6.png') no-repeat center center;
    background-size: cover;
}

.training_7 {
    background: url('@/assets/images/training/training_7.png') no-repeat center center;
    background-size: cover;
}

.training {
    width: 200px;
    height: 200px;
    padding: 10px;
    position: relative;
}

.text_area {
    font-size: 14px;
    background: rgba(0, 0, 0, .7);
}

.skills {
    display: flex;
    flex-wrap: wrap
}

.pirate_skills_training {
    width: 53%;
    margin: 0 auto;
    overflow: hidden auto;
    height: 550px;
}

.train_skill {
    position: relative;
    top: 100px;
}


</style>