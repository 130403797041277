
<template>
    <div id="waves-container" ref="wavesContainer">
        <div v-for="wave in waves" :key="wave.id" :style="{ top: wave.top + 'px', left: wave.left + 'px' }" :class="wave.classWave"></div>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

export default {
    props: {
        positionX: Number,
        positionY: Number
    },
    setup(props) {
        const waves = ref([]);
        const wavesContainer = ref(null);
        let waveTimer = null;

        const generateWaves = () => {
            if (waveTimer) {
                clearInterval(waveTimer);
            }

            const getRandomInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

            waveTimer = setInterval(() => {
                const currentTime = Date.now();
                waves.value = waves.value.filter(wave => currentTime - wave.startTime < 5000);

                const waveAreaWidth = window.innerWidth;
                const waveAreaHeight = window.innerHeight;

                for (let i = 0; i < 20; i++) {
                    const top = Math.random() * waveAreaHeight - props.positionY; // Kompensowanie przesunięcia w górę
                    const left = Math.random() * waveAreaWidth - props.positionX; // Kompensowanie przesunięcia w lewo

                    const wave = {
                        id: currentTime + i,
                        top: top,
                        left: left,
                        startTime: currentTime,
                        classWave: 'wave wave_' + getRandomInt(1, 3)
                    };
                    waves.value.push(wave);
                }
            }, 1000);
        };

        watch(() => props.positionX, () => {
            generateWaves();
        });
        watch(() => props.positionY, () => {
            generateWaves();
        });

        onMounted(() => {
            generateWaves();
        });

        onBeforeUnmount(() => {
            if (waveTimer) {
                clearInterval(waveTimer);
            }
        });

        return {
            waves,
            wavesContainer
        };
    }
}
</script>

<style scoped>
@keyframes fadeWave {
    from {
        opacity: 0;
        transform: translateX(0);
    }
    25%, 75% {
        opacity: 1;
    }
    to {
        opacity: 0;
        transform: translateX(20px);
    }
}

.wave {
    width: 33px;
    height: 10px;
    background-size: cover;
    position: absolute;
    animation: fadeWave 5s ease-in-out infinite;
    z-index: 1;
}

.wave_1 {
    background: url('@/assets/images/sprite.png') -967px -4px no-repeat;
}
.wave_2 {
    background: url('@/assets/images/sprite.png') -970px -19px no-repeat;
}
.wave_3 {
    background: url('@/assets/images/sprite.png') -980px -35px no-repeat;
}
</style>
