<template>
    <LoadingComponent :isLoading="isLoading"/>
    <div class="game_page">
        <div class="dice_area left_position">
            <div v-if="goldPool > 0" class="win_pool">
                <p>Potencjalne wygrane:</p>
                    <div>🏆 Poker (5 takich samych): {{ pokerReward }} sztuk złota</div>
                    <div>🎲 Kareta (4 takie same): {{ karetaReward }} sztuk złota</div>
                    <div>🃏 Full House (3 + 2): {{ fullHouseReward }} sztuk złota</div>
                    <div>🎲 Trójka (3 takie same): {{ trojkaReward }} sztuk złota</div>
                    <div>🎲 Dwie pary: {{ dwieParyReward }} sztuk złota</div>
                    <div>🎲 Para: {{ paraReward }} sztuk złota</div>
            </div>
        </div>
        <div class="final_game">
            <div v-if="playerDice.length" class="flex_47">
                <div class="message_area">Ty wyrzuciłeś kości:</div>
                <div class="dice_container">
                    <div
                        v-for="(die, index) in playerDice"
                        :key="index"
                        :class="`dice dice_${die}`"
                    ></div>
                </div>
            </div>
            <div v-if="pirateDice.length" class="flex_47">
                <div class="message_area">Pirat wyrzucił kości:</div>
                <div class="dice_container">
                    <div
                        v-for="(die, index) in pirateDice"
                        :key="index"
                        :class="`dice dice_${die}`"
                    ></div>
                </div>
            </div>
            <div class="flex_100">
                <p v-if="message" class="message_area">{{ message }}</p>
            </div>

        </div>
        <div class="dice_area button_area">
            <p>Twoje złoto: {{ playerGold }}<br>
            </p>
            <button @click="rollDice" :disabled="isRolling">Rzuć Kości za {{ bet }} szt. złota!</button>
        </div>
        <div class="toTavern" @click="goToTavern(tile_id)">&larr; Do tawerny</div>
    </div>
</template>
<script>
import API from "@/utils/axios";
import LoadingComponent from "@/components/LoadingComponent.vue";

export default {
    name: "DicePage",
    components: {
        LoadingComponent
    },
    data() {
        return {
            tile_id: this.$route.params.tile_id,
            playerDice: [],
            pirateDice: [],
            bet: 0,
            message: '',
            reward: 0,
            playerGold: 0,
            isRolling: false,
            cityName: '',
            goldPool: 0,
            isLoading: true,
        };
    },
    methods: {
        goToTavern(tile_id) {
            // Przekieruj użytkownika na mapę
            this.$router.push('/tavern/'+ tile_id);
        },
        async fetchCityDetails() {
            await API.get('city-details', {
                params: { tile_id: this.tile_id }
            })
            .then(response => {
                this.goldPool = response.data.goldPool;
                this.playerGold = response.data.playerGold;
                this.bet = response.data.bet;
            }).catch(error => {
                console.error('Błąd podczas pobierania informacji o mieście:', error);
            }).finally(() => {
                this.isLoading = false; // Zakończenie ładowania
            });
        },
        async rollDice() {
            this.isRolling = true;
            this.playerDice = [];
            this.pirateDice = [];
            this.message = '';

            await API.post('play-dice', {
                bet: this.bet,
                tile_id: this.tile_id
            })
            .then(response => {
                this.playerDice = response.data.playerDice;
                this.pirateDice = response.data.pirateDice;
                this.message = response.data.message;
                this.reward = response.data.reward;
                this.goldPool = response.data.current_pool;
                this.playerGold = response.data.player_gold;
                this.bet = response.data.bet;
            }).catch(error => {
                console.error(error);
                this.message = error.response.data.message || 'Wystąpił błąd.';
            }).finally(() => {
                this.isLoading = false; // Zakończenie ładowania
                this.isRolling = false;
            });
        },
    },
    created() {
        // Przykład: Pobieramy informacje o mieście o ID 1 przy załadowaniu komponentu
        this.fetchCityDetails();
    },
    computed: {
        pokerReward() {
            return this.goldPool; // 100% puli
        },
        karetaReward() {
            return Math.floor(this.goldPool * 0.50); // 50% puli
        },
        fullHouseReward() {
            return Math.floor(this.goldPool * 0.20); // 20% puli
        },
        trojkaReward() {
            return Math.floor(this.goldPool * 0.15); // 15% puli
        },
        dwieParyReward() {
            return Math.floor(this.goldPool * 0.12); // 12% puli
        },
        paraReward() {
            return Math.floor(this.goldPool * 0.10); // Para - 10% puli
        }
    }
};
</script>

<style scoped>

.game_page {
    width: 100%;
    height: 100%;
    position: relative;
    background: url('@/assets/images/game_page.png') no-repeat center center fixed;
    background-size: cover;
}

.win_pool {
    flex: 0 0 50%;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
}

.dice_area {
    position: relative;
    background: rgba(0, 0, 0, .7);
    padding: 20px;
    width: 25%;
    color: #adff2f;
}

.flex_47 {
    flex: 0 0 47%;
}

.flex_100 {
    flex: 0 0 100%;
}

.message_area {
    background: rgba(0, 0, 0, .7);
    color: #adff2f;
    display: inline-block;
    padding: 5px;
}

.button_area {
    position: absolute;
    top: 0;
    right: 0;
}

.final_game {
    width: 50%;
    left: 50%;
    position: absolute;
    bottom: 12px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    transform: translateX(-50%);
}

.left_position {
    left: 180px;
}

.dice_container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 57%;
    margin: 0 auto;
    gap: 10px;
}

.dice {
    width: 50px;
    height: 50px;
    animation: roll 1s linear 1;
}

@keyframes roll {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(90deg) translateX(10px) translateY(10px); }
    50% { transform: rotate(180deg) translateX(-10px) translateY(-10px); }
    75% { transform: rotate(270deg) translateX(10px) translateY(10px); }
    100% { transform: rotate(360deg); }
}

.dice_1 {
    background: url('@/assets/images/dices/dice_1.png') no-repeat center center;
    background-size: cover;
}

.dice_2 {
    background: url('@/assets/images/dices/dice_2.png') no-repeat center center;
    background-size: cover;
}

.dice_3 {
    background: url('@/assets/images/dices/dice_3.png') no-repeat center center;
    background-size: cover;
}

.dice_4 {
    background: url('@/assets/images/dices/dice_4.png') no-repeat center center;
    background-size: cover;
}

.dice_5 {
    background: url('@/assets/images/dices/dice_5.png') no-repeat center center;
    background-size: cover;
}

.dice_6 {
    background: url('@/assets/images/dices/dice_6.png') no-repeat center center;
    background-size: cover;
}
</style>