<template>
    <div v-if="maxWorkers > 0">
        <form @submit.prevent="assignWorkers">
            <input type="number" min="0" step="10" :max="maxWorkers" v-model="citizensToWork" @input="adjustValue" @change="handleChange"/> osób przydzielam do
            <select v-model="product_id" @change="handleChange">
                <option v-for="product_id in getProductRange(userData.producer)" :key="product_id" :value="product_id">
                    prod {{ product_id }}, {{ userLang.products['product_' + product_id] }}
                </option>
            </select><br><br>
            Będą pracować:
            <select v-model="work_time" @change="handleChange">
                <option :value="1">{{ userLang.app.one_hour }}</option>
                <option :value="2">{{ userLang.app.two_hour }}</option>
                <option :value="8">{{ userLang.app.eight_hour }}</option>
                <option :value="16">{{ userLang.app.sixteen_hour }}</option>
            </select><br>
            <input type="checkbox" v-model="continueCheckbox" :value="1"/> Kontynuuj aż zakończę
            <button @click="submitForm">Zatwierdź produkcję</button>
            <!-- Modal -->
            <modal v-if="showModal">
                <div class="modal-content">
                    <span class="close" @click="showModal = false">&times;</span>
                    <p>Zaznaczenie automatycznego przedłużenia produkcji kosztuje 10 szt. złota!</p>
                    <button @click="addWithContinuationAndClose">Tak, płacę złotem, przedłużaj aż zakończę</button>
                    <button @click="addWithoutContinuation">Dodaj bez kontynuacji</button>
                </div>
            </modal>
        </form>
        <div v-if="responseText">{{ responseText }}</div>
    </div>
    <div v-else>
        <br><br>
        Wszyscy pracownicy pracują!<br>
        Zaczekaj aż pojawią się nowi pracownicy na wyspie.<br>
        Możesz też zatrzymać realizowane zadania lub czekać aż pracownicy zakończą pracę.<br>
    </div>
</template>

<script>

import API from "@/utils/axios";

export default {
    name: "WorkersForm.vue",
    props: {
        userData: {
            type: Object,
            required: true
        },
        userLang: {
            type: Object,
            required: true
        },
        maxWorkers: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            responseText: '',
            showModal: false,
            citizensToWork: 0,
        }
    },
    methods: {
        submitForm() {
            if (this.continueCheckbox) {
                this.addWithContinuation();
                this.showModal = true;
            } else {
                this.addWithoutContinuation();
            }
        },
        getProductRange(producer) {
            switch (producer) {
                case 1:
                    return [1, 2, 3, 4, 5, 6, 7, 22];
                case 2:
                    return [8, 9, 10, 11, 12, 13, 14, 22];
                case 3:
                    return [15, 16, 17, 18, 19, 20, 21, 22];
                default:
                    return [];
            }
        },
        handleChange() {
            this.checkWorkEffect();
        },
        addWithContinuation() {
            // Tutaj wykonaj logikę związana z odpowiedzią użytkownika
            this.setProduction();
            this.showModal = false;
        },
        addWithoutContinuation() {
            this.continueCheckbox = 0;
            this.setProduction();
            this.showModal = false;
        },
        addWithContinuationAndClose() {
            this.addWithContinuation();
            this.showModal = false;
        },
        async checkWorkEffect() {
            try {
                // Sprawdzenie, czy wszystkie trzy wartości są zdefiniowane
                if (this.citizensToWork && this.product_id && this.work_time) {

                    const dataParams = {
                        citizensToWork: this.citizensToWork,
                        product_id: this.product_id,
                        work_time: this.work_time
                    };

                    API.post(`/checkWorkEffect`, dataParams)
                    .then(response => {
                        this.responseText = response.data.message;
                    })
                    .catch(error => {
                        console.error('Błąd podczas wysyłania danych:', error);
                        this.responseText = 'Wystąpił błąd podczas wysyłania danych.';
                    });
                } else {
                    // Wyświetl komunikat, że wszystkie trzy wartości muszą być wybrane
                    this.responseText = 'Musisz wybrać wszystkie trzy elementy.';
                }
            } catch (error) {
                console.error('Błąd podczas wysyłania danych:', error);
                this.responseText = 'Wystąpił błąd podczas wysyłania danych.';
            }
        },
        adjustValue() {
            if (this.citizensToWork > this.maxWorkers) {
                this.citizensToWork = this.maxWorkers;
            }

            if (this.citizensToWork < 0) {
                this.citizensToWork = 0;
            }
        },
        setProduction() {
            try {
                // Sprawdzenie, czy wszystkie trzy wartości są zdefiniowane
                if (this.citizensToWork && this.product_id && this.work_time) {
                    const dataParams = {
                        citizensToWork: this.citizensToWork,
                        product_id: this.product_id,
                        work_time: this.work_time,
                        continue: this.continueCheckbox
                    };

                    API.post(`/setProduction`, dataParams)
                    .then(response => {
                        this.responseText = response.data.message;
                        this.$emit('productionUpdated');
                    })
                    .catch(error => {
                        console.error('Błąd podczas wysyłania danych:', error);
                        this.responseText = 'Wystąpił błąd podczas wysyłania danych.';
                    });
                } else {
                    // Wyświetl komunikat, że wszystkie trzy wartości muszą być wybrane
                    this.responseText = 'Musisz wybrać wszystkie trzy elementy.';
                }
            } catch (error) {
                console.error('Błąd podczas wysyłania danych:', error);
                this.responseText = 'Wystąpił błąd podczas wysyłania danych.';
            }
        }
    }
}
</script>

<style scoped>

</style>