<template>
    <div class="col-md-6 col-lg-4 offset-lg-4 offset-md-3 mt-5">
        <router-link to="/" class="btn btn-primary me-2">Strona główna</router-link>
        <router-link to="/login" class="btn btn-primary me-2">Logowanie</router-link>
        <router-link to="/register" class="btn btn-primary me-2">Rejestracja</router-link>
        <div class="bg-light p-5 border shadow">
            <!-- Reset Password Form -->
            <form @submit.prevent="resetPassword">
                <div class="mb-4">
                    <input type="email" class="form-control" v-model="email" placeholder="podaj email">
                </div>
                <button type="submit" class="btn btn-primary w-100 my-3 shadow">Reset Password</button>
            </form>
            <!-- Reset Password Form -->
        </div>
    </div>
</template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      email: ''
    };
  },
  methods: {
    async resetPassword() {
      try {
        await axios.post(`${this.$apiUrl}/reset-password`, {
          email: this.email
        });
      } catch (error) {
        console.error(error);
      }
    }
  }
}
</script>

<style scoped>
/* Style dla komponentu */
</style>