<template>
    <div id="navbar-area">
        <span v-tooltip tooltip="przesuń mapkę do góry" :disabled="!moveUp" class="direction north" @click="move('up')"></span>
        <span v-tooltip tooltip="przesuń mapkę w prawo" :disabled="!moveRight" class="direction east" @click="move('right')"></span>
        <span v-tooltip tooltip="przesuń mapkę w dół" :disabled="!moveDown" class="direction south" @click="move('down')"></span>
        <span v-tooltip tooltip="przesuń mapkę w lewo" :disabled="!moveLeft" class="direction west" @click="move('left')"></span>
        <!--<span v-tooltip tooltip="powiększ" class="direction zoom_in" @click="move('zoom_in')">-</span>
        <span v-tooltip tooltip="pomniejsz" class="direction zoom_out" @click="move('zoom_out')">+</span>-->
    </div>
</template>

<script>
export default {
    props: ['moveUp', 'moveRight', 'zoom', 'moveDown', 'moveLeft'],
    methods: {
        move(direction) {
            this.$emit('move', direction);
        }
    }
};
</script>

<style scoped>

#navbar-area {
    width: 200px;
    height: 200px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: url('@/assets/images/compass.png') no-repeat center center;
    position: fixed;
    top: 15px;
    right: 15px;
}

.direction {
    width: 40px; /* Szerokość przycisku */
    height: 40px; /* Wysokość przycisku */
    border: none;
    background-color: transparent; /* Tło przezroczyste, może być też kolorowe */
    cursor: pointer;
    position: absolute;
    font-size: 16px;
    font-weight: bold;
    z-index: 3;
    color: #152f44;
}

.north { top: 0; left: 50%; transform: translateX(-50%); }
.south { bottom: 0; left: 50%; transform: translateX(-50%); }
.east { right: 0; top: 50%; transform: translateY(-50%); }
.west { left: 0; top: 50%; transform: translateY(-50%); }

.zoom_in { bottom: 0; right: 0; font-size: 30px; }

.zoom_out { bottom: 0; left: 0; font-size: 30px; }

</style>
