export default {
    mounted(el) {
        const tooltipContainer = document.getElementById('infoTooltip');
        if (!tooltipContainer) {
            console.error('Tooltip container not found');
            return;
        }

        let originalTitle = el.getAttribute('tooltip');
        const updateTooltip = (event) => {
            if (originalTitle) {
                tooltipContainer.innerHTML = originalTitle;
                let dY = event.pageY;
                let dX = event.pageX;

                const tooltipWidth = tooltipContainer.offsetWidth;
                const tooltipHeight = tooltipContainer.offsetHeight;
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;

                if ((tooltipWidth + dX) > windowWidth) {
                    dX = event.pageX - tooltipWidth;
                }

                if ((tooltipHeight + dY) > windowHeight) {
                    dY = event.pageY - tooltipHeight;
                }
                tooltipContainer.style.left = `${dX}px`;
                tooltipContainer.style.top = `${dY}px`;
                tooltipContainer.classList.add('fade-in');
                tooltipContainer.classList.remove('fade-out');
            } else {
                tooltipContainer.classList.remove('fade-in');
                tooltipContainer.classList.add('fade-out');
            }
        };

        // Event listeners for tooltip
        el.addEventListener('mouseenter', (event) => {
            updateTooltip(event);
        });

        el.addEventListener('mousemove', (event) => {
            updateTooltip(event); // Update tooltip position on mouse move
        });

        el.addEventListener('mouseleave', () => {
            tooltipContainer.classList.remove('fade-in');
            tooltipContainer.classList.add('fade-out');
            tooltipContainer.style.left = '';
            tooltipContainer.style.top = '';
            tooltipContainer.innerHTML = '';
        });

        // MutationObserver to watch for attribute changes
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'attributes' && mutation.attributeName === 'tooltip') {
                    originalTitle = el.getAttribute('tooltip');
                }
            });
        });

        // Start observing the element
        observer.observe(el, {
            attributes: true
        });

        el.__tooltipObserver = observer;
    },
    unmounted(el) {
        const tooltipContainer = document.getElementById('infoTooltip');
        if (tooltipContainer) {
            tooltipContainer.classList.remove('fade-in');
            tooltipContainer.classList.add('fade-out');
            tooltipContainer.style.left = '';
            tooltipContainer.style.top = '';
            tooltipContainer.innerHTML = '';
        }

        if (el.__tooltipObserver) {
            el.__tooltipObserver.disconnect();
            delete el.__tooltipObserver;
        }
    }
};
