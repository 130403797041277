<template>
    <div class="map-grid">
        <TimerComponent v-if="timerActive" :endTime="initialTime" :action="timerAction"></TimerComponent>
        <div v-for="piece in mapPieces" :key="piece.piece_number" class="map-cell">
            <div v-if="piece.count > 0">
                <img :src="mapPieceImages[piece.piece_number]" :alt="'Map piece ' + piece.piece_number">
                <span class="piece-count" v-if="piece.count > 1">{{ piece.count }}</span>
            </div>
            <div v-else class="empty-map-piece"></div>
        </div>
        <button v-if="allPiecesCollected" @click="sendCrewForTreasure" class="treasure-button">Wyślij załogę po skarb</button>
    </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import {ref} from 'vue';
import TimerComponent from '@/components/TimerComponent.vue';
import API from "@/utils/axios";

export default {
    name: 'TreasureMap',
    components: {
        TimerComponent
    },
    setup() {
        const initialTime = ref(120);
        const timerAction = ref(0);
        const timerActive = ref(false);
        const sendCrewForTreasure = () => {
            initialTime.value = 60; // Resetuje czas
            timerAction.value = 1; // Zmienia akcję
            timerActive.value = true; // Pokazujemy timer

            const data = {
                timeLeft: initialTime.value,
                action: timerAction.value
            };

            API.post(`/saveTime`, data)
            .then(response => {
                console.log(response.data.message);
            }).catch(error => {
                console.error('Error saving march time:', error);
            });
        };

        return { initialTime, timerAction, timerActive, sendCrewForTreasure };
    },
    computed: {
        ...mapState(['mapPieces']),
        allPiecesCollected() {
            return Object.values(this.mapPieces).every(piece => piece.count > 0);
        },
        mapPieceImages() {
            return Object.keys(this.mapPieces).reduce((acc, key) => {
                const piece = this.mapPieces[key];
              // eslint-disable-next-line no-undef
                acc[key] = require('@/assets/images/treasures_map/element_' + piece.piece_number + '.png');
                return acc;
            }, {});
        }
    },
    methods: {
        ...mapActions(['fetchMapPieces', 'collectMapPiece'])
    },
    mounted() {
        this.fetchMapPieces();

        const data = {
            action: 1
        };

        API.get(`/getTime`, data)
        .then(response => {
            this.initialTime = response.data.timeLeft;
            this.timerAction = 1;
            this.timerActive = true;
        })
        .catch(error => {
            console.error('Error fetching march time:', error);
        });
    }
}
</script>

<style scoped>
.map-grid {
    display: grid;
    grid-template-columns: repeat(4, 50px);
    grid-gap: 2px;
    width: 208px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
}

.map-cell {
    width: 50px;
    height: 50px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-cell img {
    width: 100%;
    height: 100%;
}

.piece-count {
    position: absolute;
    top: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 2px 5px;
}

.empty-map-piece {
    width: 50px;
    height: 50px;
    background-color: #eee;
}

.treasure-button {
    grid-column: 1 / -1;
    margin-top: 20px;
}

</style>
