<template>
    <router-view v-if="!isLoading"/>
    <div id="infoTooltip" class="tooltip"></div>
    <ChatComponent v-if="userId" :user_id="userId" />
</template>

<script>

import ChatComponent from '@/components/ChatComponent.vue';
import { mapGetters } from "vuex";

export default {

    data() {
        return {
            userId: null, // Domyślna wartość, jeśli user_id nie istnieje
            isLoading: true,
        };
    },
    components: {
        ChatComponent,
    },
    name: 'App',
    methods: {
      ...mapGetters(["getUserData"]),
    },
    async mounted() {
        const defaultLang = 'pl';
        const translations = this.$store.getters.getLangTranslations;
        const currentLang = this.$store.getters.getSUserLang || defaultLang;

        if (!translations || Object.keys(translations).length === 0) {
            console.log('Ładowanie domyślnych tłumaczeń...');
            await this.$store.dispatch('fetchTranslations', currentLang);
            const newTranslations = this.$store.getters.getLangTranslations;

            this.$i18n.setLocaleMessage(currentLang, newTranslations);
            this.$i18n.locale = currentLang;
        } else {
            this.$i18n.setLocaleMessage(currentLang, translations);
            this.$i18n.locale = currentLang;
        }
        const userData = this.getUserData();
        if (userData) {
          console.log('userdata', userData);
          this.userId = userData.id;
          console.log(`Zalogowany użytkownik: ${userData.id}`);
        } else {
          console.log("Użytkownik niezalogowany");
        }
        this.isLoading = false;
        await this.$store.dispatch('fetchGameData');
    }
}
</script>

<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box; /* To pomoże zarządzać box model w sposób bardziej przewidywalny */
}
/* Stylizacja dla głównego komponentu */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;  /* Ważne, aby rozciągnąć na całą wysokość */
    width: 100%;   /* Ważne, aby rozciągnąć na całą szerokość */
    overflow: hidden; /* Zapobiega przewijaniu, jeśli nie jest potrzebne */
}

#app {
    height: 100%;
    width: 100%;
    text-align: center;
}

.tooltip {
    position: absolute;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    color: greenyellow;
    background: black;
    max-width: 300px;
    pointer-events: none;
    z-index: 15;
}

.tooltip.fade-in {
    opacity: 1;
    display: block;
}

.tooltip.fade-out {
    opacity: 0;
    display: none;
}

.chat-window {
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    overflow-y: auto;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.chat-messages {
    height: 90%;
    overflow-y: auto;
}

.message {
    margin-bottom: 5px;
}

input {
    width: 100%;
    box-sizing: border-box;
}

</style>
